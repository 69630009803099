/* SCSS */
import "../scss/vendor.scss"

/* JS */
// Babel Polyfills (Enable use of modern javascript)
import "core-js/stable";
import "regenerator-runtime/runtime";
// End of babel polyfills

import * as bootstrap from "bootstrap"
window.bootstrap = bootstrap
